import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'yellowla.com',
  theme: {},
  globalNavProps: {
    defaultGroupKey: 'snap',
  },
  siteMap: {
    customPaths: {
      // Top level domain, should include cookie-settings in site map
      '/cookie-settings': { isLocalized: true },
    },
  },
  trackingSettings: {
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.YELLOW_LA },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'UA-41740027-44',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'yellowla-com',
        dsn: 'https://1d2180a5ab9042e29027879f82361602@sentry.sc-prod.net/79',
      },
    ],
  },
};
